import React from "react";
import CircleCheck from "./CircleCheck";
import { ChevronRight } from "lucide-react";

interface FeatureCardProps {
  color: "teal" | "gradient" | "blue";
  icon?: "checkbox" | "link";
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  learnMoreLink?: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  color,
  icon = "checkbox",
  title,
  subtitle,
  children,
  learnMoreLink,
}) => {
  return (
    <div className="border border-gray-10 py-4 px-5 rounded-lg flex-1">
      <div className="mb-3 flex flex-row items-center justify-start whitespace-nowrap">
        <CircleCheck icon={icon} color={color} />{" "}
        <div className="ml-3 text-md font-semibold flex flex-row items-center whitespace-nowrap mr-3">
          {title}{" "}
          {subtitle && <div className="ml-1 font-normal text-sm whitespace-nowrap">{subtitle}</div>}
        </div>
        {learnMoreLink && (
          <a
            className="ml-auto flex cursor-pointer flex-row items-center text-sm justify-center font-semibold text-blue-40 hover:text-blue-60"
            href={learnMoreLink}
            target="_blank"
          >
            Learn more <ChevronRight className="ml-1" size={12} />
          </a>
        )}
      </div>
      <div className="text-left">{children}</div>
    </div>
  );
};

export default FeatureCard;
