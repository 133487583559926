import React from "react";
import { CategoryIntegrationObject, SupportedFeaturesType, generateCSVData } from "../helpers";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { CSVLink } from "react-csv";
import CommonModelColumn from "./CommonModelColumn";
import TableRows from "./TableRows";
import { APICategory } from "@merge-api/merge-javascript-shared";

interface SupportedFeaturesTableProps {
  supportedFeatures: SupportedFeaturesType;
  selectedCommonModelNames: string[];
  selectedIntegrationNames: string[];
  allIntegrations: CategoryIntegrationObject;
  isOnDeletedDataPage: boolean;
  categoryName: APICategory;
}

const SupportedFeaturesTable = ({
  supportedFeatures,
  selectedCommonModelNames,
  selectedIntegrationNames,
  allIntegrations,
  isOnDeletedDataPage,
  categoryName,
}: SupportedFeaturesTableProps) => {
  return (
    <div className="w-full mt-6">
      <div className="flex justify-end">
        <Button size="sm" variant={ButtonVariant.TertiaryWhite}>
          <CSVLink
            className="text-black"
            data={generateCSVData(
              selectedIntegrationNames,
              selectedCommonModelNames,
              supportedFeatures,
              isOnDeletedDataPage,
            )}
          >
            Export as CSV
          </CSVLink>
        </Button>
      </div>
      <div className="flex my-6">
        <div>
          <CommonModelColumn selectedCommonModelNames={selectedCommonModelNames} />
        </div>
        <TableRows
          allIntegrations={allIntegrations}
          selectedIntegrationNames={selectedIntegrationNames}
          supportedFeatures={supportedFeatures}
          selectedCommonModelNames={selectedCommonModelNames}
          isOnDeletedDataPage={isOnDeletedDataPage}
          categoryName={categoryName}
        />
      </div>
    </div>
  );
};

export default SupportedFeaturesTable;
