import React from "react";
import FeatureCard from "./components/FeatureCard";
import {
  MERGE_DELETION_DETECTION_GUIDE,
  MERGE_PRICING_PAGE,
} from "components/docs/navigation/links";

const DeletionDetectionTabProps = () => {
  return (
    <>
      <div className="mb-6">
        If a model is deleted in your customers' third-party platform, Merge will update the{" "}
        <code className="px-[5px] font-normal items-center rounded-[3px] bg-gray-0 h-fit w-fit font-mono text-[13px] text-gray-90 flex-nowrap">
          remote was deleted
        </code>{" "}
        field on the respective Common Model to{" "}
        <code className="px-[5px] font-normal items-center rounded-[3px] bg-gray-0 h-fit w-fit font-mono text-[13px] text-gray-90 flex-nowrap">
          true
        </code>
        .
      </div>
      <div className="flex flex-col s-f-bp:flex-row s-f-bp:space-x-6 s-f-bp:space-y-0 space-y-6 w-full">
        <FeatureCard color="teal" title="Native deleted data">
          <p className="mb-3">
            Merge provides native deleted data detection for many fields by default{" "}
            <b>updated during each sync</b>. Support for this feature varies by platform.
          </p>
          <p className="mb-0">This is free for all customers.</p>
        </FeatureCard>
        <FeatureCard
          color="gradient"
          title="Enhanced deleted data"
          subtitle="(includes native deleted data)"
          learnMoreLink={MERGE_DELETION_DETECTION_GUIDE}
        >
          <p className="mb-3">
            Enhanced deleted data detection is offered by Merge to fill the gaps in native deleted
            data detection via a full sync <b>every 3 days</b>.
          </p>
          <p className="mb-0">
            This can be purchased as a premium plan add-on.{" "}
            <a href={MERGE_PRICING_PAGE} target="_blank">
              View plans
            </a>
          </p>
        </FeatureCard>
      </div>
    </>
  );
};

export default DeletionDetectionTabProps;
