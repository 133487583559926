import React from "react";
import MergeLogo from "../../../../../assets/svg/logos/merge/merge-features-table.svg";
import styled from "styled-components";

interface CommonModelColumnProps {
  selectedCommonModelNames: string[];
}

type TableCellProps = {
  isLastCell?: boolean;
  isFirstCell?: boolean;
};

const TableCell = styled.div<TableCellProps>`
  box-shadow: ${(props) => {
    if (props.isFirstCell) {
      return "0px 1px 0px #eaeef3";
    }
    return props.isLastCell ? "" : "1px 0px 0px #eaeef3, 0px 1px 0px #eaeef3";
  }};
  border-bottom-right-radius: ${(props) => props.isLastCell && "8px"} !important;
`;

const CommonModelColumn = ({ selectedCommonModelNames }: CommonModelColumnProps) => {
  return (
    <div className="flex flex-col bg-white rounded-tl-lg rounded-tr-lg rounded-b-lg shadow-[0_6px_30px_-2px_rgba(0,0,0,0.12)]">
      <TableCell
        isFirstCell={true}
        className="h-9 w-[184px] font-semibold text-gray-90 text-base py-2 px-3 mb-0 rounded-t-lg flex flex-row items-center"
      >
        <MergeLogo className="mr-2" /> Merge
      </TableCell>
      {selectedCommonModelNames.map((modelName, index) => {
        const isLastCell = index === selectedCommonModelNames.length - 1;
        return (
          <TableCell
            className="flex items-center h-9 w-[184px] py-2 px-3 overflow-hidden text-left text-md font-medium text-slate-90"
            isLastCell={isLastCell}
          >
            {modelName}
          </TableCell>
        );
      })}
    </div>
  );
};

export default CommonModelColumn;
