import MultiSelectSearchBar from "components/docs/integrations/MultiSelectSearchBar";
import React from "react";
type SuggestedSearchProps = {
  title: string;
  className?: string;
  dropDownOptions: string[];
  handleOptionSelect: any;
  selectedOptions: string[];
  style?: React.CSSProperties;
};

const MultiSelectSearch = ({
  className,
  title,
  dropDownOptions,
  handleOptionSelect,
  selectedOptions,
}: SuggestedSearchProps) => {
  const mappedDropdownOptions = dropDownOptions.map((option) => ({ value: option, label: option }));

  return (
    <div className={className}>
      <div className="text-med text-slate-90 font-semibold mb-2">{title}</div>
      <MultiSelectSearchBar
        dropDownOptions={mappedDropdownOptions}
        value={selectedOptions.map((option) => ({ value: option, label: option }))}
        onChange={(selectedOptions, actionMeta) => handleOptionSelect(selectedOptions, actionMeta)}
      />
    </div>
  );
};

export default MultiSelectSearch;
