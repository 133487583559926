import React from "react";
import { Check, ArrowUpRight } from "lucide-react";
import clsx from "clsx";

interface CheckboxProps {
  color: "gradient" | "teal" | "blue";
  icon?: "checkbox" | "link";
}

const CircleCheck: React.FC<CheckboxProps> = ({ color, icon = "checkbox" }) => {
  const gradientStyle =
    color === "gradient"
      ? {
          background: "linear-gradient(91deg, #fbd046 2.98%, #faa412 98.01%)",
        }
      : {};

  const bgColorClass = clsx({
    "bg-teal-50": color === "teal",
    "bg-blue-40": color === "blue", // Assuming blue40 maps to a real Tailwind class
  });

  const Icon = icon === "checkbox" ? Check : ArrowUpRight;

  return (
    <div
      style={gradientStyle}
      className={clsx(
        "rounded-full h-4 w-4 flex justify-center items-center flex-shrink-0",
        bgColorClass,
      )}
    >
      <Icon className="text-white" size={12} />
    </div>
  );
};

export default CircleCheck;
