import React from "react";
import {
  SupportedFeaturesType,
  getCategoryIntegrationInformation,
  CategoryIntegrationObject,
} from "../helpers";
import IntegrationHeaderRow from "./IntegrationHeaderRow";
import styled from "styled-components";
import CircleCheck from "./CircleCheck";
import {
  MERGE_DOCS_AUTOMATIC_WEBHOOKS_GUIDE,
  MERGE_DOCS_BASE_URL,
} from "components/docs/navigation/links";
import { ArrowUpRight, Info } from "lucide-react";
import { APICategory } from "@merge-api/merge-javascript-shared";
import Tooltip from "components/generic/Tooltip";

interface SupportedFeaturesTableProps {
  supportedFeatures: SupportedFeaturesType;
  selectedCommonModelNames: string[];
  selectedIntegrationNames: string[];
  allIntegrations: CategoryIntegrationObject;
  isOnDeletedDataPage: boolean;
  categoryName: APICategory;
}

type TableCellProps = {
  isLastRow: boolean;
};

const TableCell = styled.div<TableCellProps>`
  box-shadow: ${(props) =>
    props.isLastRow
      ? "1px 0px 0px #eaeef3, 0px 1px 0px #C8CFDA"
      : "1px 0px 0px #eaeef3, 0px 1px 0px #eaeef3"};
`;

const TableRows: React.FC<SupportedFeaturesTableProps> = ({
  supportedFeatures,
  selectedCommonModelNames,
  categoryName,
  selectedIntegrationNames,
  allIntegrations,
  isOnDeletedDataPage,
}) => {
  const fallBackWebhookURL = MERGE_DOCS_BASE_URL + MERGE_DOCS_AUTOMATIC_WEBHOOKS_GUIDE;
  const categoryIntegrationInformation = getCategoryIntegrationInformation(
    selectedIntegrationNames,
    allIntegrations,
  );

  const modelExceptions = ["Group", "User"];

  return (
    <div className="overflow-x-auto overflow-y-hidden w-full flex flex-nowrap rounded-b-lg shadow-[0_4px_20px_-4px_rgba(0,0,0,0.08)]">
      <div className="flex flex-col">
        <div className="flex">
          <IntegrationHeaderRow integrationInformation={categoryIntegrationInformation} />
        </div>
        {selectedCommonModelNames.map((modelName, index) => {
          const isLastRow = index === selectedCommonModelNames.length - 1;
          return (
            <div className="flex">
              {categoryIntegrationInformation.map((integration) => {
                const featureDetail =
                  supportedFeatures[`${modelName}/${integration.integrationName}`];
                const isFileStorageExceptions =
                  categoryName === "filestorage" &&
                  modelExceptions.includes(modelName) &&
                  !isOnDeletedDataPage &&
                  featureDetail?.hasAutoWebhookSupport;

                return (
                  <TableCell
                    className="py-2 px-3 h-9 w-[164px] flex justify-center items-center relative"
                    isLastRow={isLastRow}
                  >
                    {isOnDeletedDataPage ? (
                      featureDetail?.hasDeletionDetection ? (
                        <CircleCheck color="teal" />
                      ) : (
                        <CircleCheck color="gradient" />
                      )
                    ) : featureDetail?.hasAutoWebhookSupport ? (
                      <CircleCheck color="gradient" />
                    ) : featureDetail?.hasManualWebhookSupport ? (
                      <a
                        className="text-blue-40 hover:text-blue-60 text-sm font-semibold flex flex-row items-center justify-center"
                        href={integration.webhookSetupGuideURL || fallBackWebhookURL}
                        target="_blank"
                      >
                        See guide <ArrowUpRight className="ml-0.5" size={12} />
                      </a>
                    ) : null}
                    {isFileStorageExceptions && (
                      <div className="absolute right-3">
                        <Tooltip
                          delay={100}
                          content="Webhooks only capture changes when a Group is added/removed to a File or Folder."
                        >
                          <Info className="text-gray-50" size={12} />
                        </Tooltip>
                      </div>
                    )}
                  </TableCell>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableRows;
