import React from "react";
import clsx from "clsx";

interface MultiSwitchProps {
  isOnDeletedDataPage: boolean;
  setIsOnDeletedDataPage: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
}

const MultiSwitch: React.FC<MultiSwitchProps> = ({
  isOnDeletedDataPage,
  setIsOnDeletedDataPage,
  className,
}) => {
  const baseButtonClass = "w-[177px] h-[40px] text-md text-center font-semibold";
  const activeButtonClass = "bg-black text-white rounded-md";
  const inactiveButtonClass = "bg-gray-0 text-gray-60";

  return (
    <div className={clsx("text-left", className)}>
      <div className="text-md font-semibold text-slate-90 mb-2">Select feature</div>
      <div className="flex bg-gray-0 w-fit rounded-md">
        <button
          className={clsx(
            baseButtonClass,
            isOnDeletedDataPage ? activeButtonClass : inactiveButtonClass,
            { "rounded-tl-md rounded-bl-md": !isOnDeletedDataPage },
          )}
          onClick={() => setIsOnDeletedDataPage(true)}
        >
          Deleted data
        </button>
        <button
          className={clsx(
            baseButtonClass,
            !isOnDeletedDataPage ? activeButtonClass : inactiveButtonClass,
            { "rounded-tr-md rounded-br-md": isOnDeletedDataPage },
          )}
          onClick={() => setIsOnDeletedDataPage(false)}
        >
          Third-party webhooks
        </button>
      </div>
    </div>
  );
};

export default MultiSwitch;
