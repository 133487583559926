import { APICategory, HTTPMethod } from "@merge-api/merge-javascript-shared";
import { AvailableIntegration } from "hooks/useAvailableActions";
import { ActionMeta } from "react-select";

export const SELECT_ALL = "Select all";

export type CategoryIntegrationObject = {
  integration: Integration;
};

export type SelectedIntegrationInfo = {
  integrationName: string;
  integrationSlug: string;
  integrationSquareImage: string;
  webhookSetupGuideURL: string;
};

export type IntegrationInfo = {
  slug: string;
  square_image: string;
  name: string;
  webhookSetupGuideURL: string;
};

export type Integration = {
  integration: IntegrationInfo;
};

export interface DropdownOption {
  value: string;
  label: string;
}

export type SupportedFeaturesType = Record<
  string,
  {
    hasAutoWebhookSupport: boolean;
    hasManualWebhookSupport: boolean;
    hasDeletionDetection: boolean;
  }
>;

interface HandleSelectProps {
  selectedOptions: DropdownOption[];
  actionMeta: ActionMeta<DropdownOption>;
  setOptions: React.Dispatch<React.SetStateAction<string[]>>;
  setDropdownOptions: React.Dispatch<React.SetStateAction<string[]>>;
  input: string[];
}

export const handleSelect = ({
  selectedOptions,
  actionMeta,
  setOptions,
  setDropdownOptions,
  input,
}: HandleSelectProps) => {
  const isSelectAllOptionSelected = selectedOptions.some((option) => option.value === SELECT_ALL);
  const selectAllMetaActionDeselected =
    actionMeta.action === "deselect-option" && actionMeta.option?.value === SELECT_ALL;

  const selectAllMetaActionSelected =
    actionMeta.action === "select-option" && actionMeta.option?.value === SELECT_ALL;

  const modelMetaActionSelected =
    actionMeta.action === "select-option" && actionMeta.option?.value !== SELECT_ALL;
  const modelMetaActionDeselected =
    actionMeta.action === "deselect-option" && actionMeta.option?.value !== SELECT_ALL;

  const selectedValues = selectedOptions.map((option) => option.value);

  if (selectAllMetaActionDeselected) {
    setOptions([]);
    setDropdownOptions([]);
  }

  if (selectAllMetaActionSelected) {
    setOptions(input);
    setDropdownOptions([SELECT_ALL, ...input]);
  }

  if (modelMetaActionSelected && !isSelectAllOptionSelected) {
    setOptions(selectedValues);
    setDropdownOptions(selectedValues);
  }

  if (modelMetaActionDeselected && isSelectAllOptionSelected) {
    const deselectedOption = input.find((option) => !selectedValues.includes(option));
    setOptions(
      selectedValues.filter((option) => option !== deselectedOption && option !== SELECT_ALL),
    );
    setDropdownOptions(
      selectedValues.filter((option) => option !== deselectedOption && option !== SELECT_ALL),
    );
  } else if (modelMetaActionDeselected) {
    setOptions(selectedValues);
    setDropdownOptions(selectedValues);
  }
};

export const getCategoryIntegrationInformation = (
  selectedIntegrations: string[],
  integrations: CategoryIntegrationObject,
) => {
  return Object.values(integrations).reduce(
    (
      selectedCategoryIntegrationInformation: SelectedIntegrationInfo[],
      integrationInfo: Integration,
    ) => {
      const { name, slug, square_image, webhookSetupGuideURL } = integrationInfo.integration;
      if (selectedIntegrations.includes(name)) {
        return [
          ...selectedCategoryIntegrationInformation,
          {
            integrationName: name,
            integrationSlug: slug,
            integrationSquareImage: square_image,
            webhookSetupGuideURL: webhookSetupGuideURL,
          },
        ];
      }
      return selectedCategoryIntegrationInformation;
    },
    [],
  );
};

export const getSupportedFeatureIntegrationAndModelDetails = (
  allAvailableActions: Partial<
    Record<APICategory, Record<string, Partial<Record<HTTPMethod, AvailableIntegration[]>>>>
  >,
  category: APICategory,
  commonModelNames: string[],
  integrationNames: string[],
) => {
  const details: Record<
    string,
    {
      hasAutoWebhookSupport: boolean;
      hasManualWebhookSupport: boolean;
      hasDeletionDetection: boolean;
    }
  > = {};

  commonModelNames.forEach((modelName) => {
    if (allAvailableActions && category && allAvailableActions[category]) {
      const operationDetails = allAvailableActions[category]![modelName]?.["GET"];
      if (operationDetails) {
        operationDetails.forEach((operation: AvailableIntegration) => {
          if (integrationNames.includes(operation.integration.name)) {
            details[`${modelName}/${operation.integration.name}`] = {
              hasAutoWebhookSupport: operation.hasAutoWebhookSupport,
              hasManualWebhookSupport: operation.hasManualWebhookSupport,
              hasDeletionDetection: operation.hasDeletionDetection,
            };
          }
        });
      }
    }
  });

  return details;
};

export const getAllIntegrations = (integrations: CategoryIntegrationObject) => {
  return Object.values(integrations).map((integration) => {
    const { name } = integration.integration;
    return name;
  });
};

export const filterOnlyTrueFeatures = (
  supportedFeatures: Record<
    string,
    {
      hasAutoWebhookSupport: boolean;
      hasManualWebhookSupport: boolean;
      hasDeletionDetection: boolean;
    }
  >,
) => {
  return Object.fromEntries(
    Object.entries(supportedFeatures).filter(
      ([key, value]) =>
        value.hasAutoWebhookSupport || value.hasManualWebhookSupport || value.hasDeletionDetection,
    ),
  );
};

export const generateCSVData = (
  selectedIntegrationNames: string[],
  selectedCommonModelNames: string[],
  supportedFeature: SupportedFeaturesType,
  isOnDeletedDataPage: boolean,
): any[] => {
  const csvData: any[] = [];
  const csvHeaders = ["Merge Common Models", ...selectedIntegrationNames];

  csvData.push(csvHeaders);

  selectedCommonModelNames.forEach((commonModel) => {
    const csvPropertyIntegrationsRow = [commonModel];

    selectedIntegrationNames.forEach((integrationName) => {
      const featureDetail = supportedFeature[`${commonModel}/${integrationName}`];

      if (isOnDeletedDataPage) {
        csvPropertyIntegrationsRow.push(
          featureDetail?.hasDeletionDetection ? "Native" : "Enhanced",
        );
      } else {
        if (featureDetail?.hasAutoWebhookSupport) {
          csvPropertyIntegrationsRow.push("Automatic");
        } else if (featureDetail?.hasManualWebhookSupport) {
          csvPropertyIntegrationsRow.push("Manual");
        } else {
          csvPropertyIntegrationsRow.push("");
        }
      }
    });

    csvData.push(csvPropertyIntegrationsRow);
  });

  return csvData;
};
